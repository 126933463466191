export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE as string;

export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY as string;

export const GTM_ID = process.env.REACT_APP_GTM_ID as string;

export const SHOULD_USE_TRIAL = (
  process.env.REACT_APP_SHOULD_USE_TRIAL
    ? JSON.parse(process.env.REACT_APP_SHOULD_USE_TRIAL)
    : false
) as boolean;

export const IS_SHOW_SOLUTION_ENABLED = false as boolean;

export const IS_SOLUTIONS_LIMIT_TEST_ENABLED = (
  process.env.REACT_APP_IS_SOLUTIONS_LIMIT_TEST_ENABLED
    ? JSON.parse(process.env.REACT_APP_IS_SOLUTIONS_LIMIT_TEST_ENABLED)
    : false
) as boolean;
