import React, { memo, useCallback, useState, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { ReactComponent as ActiveIcon } from 'assets/images/active-sub.svg';
import { ReactComponent as CanceledIcon } from 'assets/images/canceld-sub.svg';
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg';

import { SHOULD_USE_TRIAL } from 'config';

import { useAnalytics } from 'modules/common/hooks/useAnalytics';
import { useClickOutside } from 'modules/common/hooks/useClickOutside';
import { Routes } from 'modules/router/components/Router/constants';
import { useAuth } from 'modules/user/components/AuthProvider/hooks';
import { useUser } from 'modules/user/components/UserProvider/hooks';
import { usePaymentModals } from 'modules/price/components/Subscriptions/components/PaymentModalsProvider';
import { CancelSubscriptionModal } from 'modules/price/components/CancelSubscriptionModal';
import { Avatar } from 'modules/common/components/Avatar';
import { Text, TextVariants, TextAsVariants } from 'modules/common/components/Text';
import { Button } from 'modules/common/components/Button';

import { Props } from './types';
import { getPaymentInfo } from './utils';
import {
  USER_AVATAR_SIZE,
  Container,
  InnerContainer,
  HeaderContainer,
  HeaderInnerContainer,
  NavigationContainer,
  Navigation,
  StyledButton,
  UserMenuContainer,
  UserMenu,
  UserAvatarContainer,
  UserMenuItemMarked,
  UserProfileContainer,
  UserSubscriptionContainer,
  UserContainer,
  SubscriptionDetails,
  LogoContainer,
  TextButton,
} from './styles';

const PageLayout: React.FC<Props> = memo(({ children }) => {
  const userMenuRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const { signIn, logout, isAuthorized } = useAuth();
  const { user, hasBasicPlan, hasProPlan, userConfig } = useUser();
  const { openSubscriptionsModal } = usePaymentModals();

  const { setGoogleTag } = useAnalytics();

  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [isCancelSubscriptionVisible, setIsCancelSubscriptionVisible] = useState(false);

  const toggleIsUserMenuVisible = useCallback(() => {
    setIsUserMenuVisible((prevIsUserMenuVisible) => !prevIsUserMenuVisible);
  }, []);

  const handleLoginClick = useCallback(() => {
    setGoogleTag({ event: 'login_click' });

    signIn();
  }, [signIn, setGoogleTag]);

  const handleDocumentsClick = useCallback(() => {
    setGoogleTag({ event: 'solver_button_click' });

    navigate(`/${Routes.Solver}`);
  }, [navigate, setGoogleTag]);

  const handleLogoutClick = useCallback(() => {
    setGoogleTag({ event: 'logout_click' });

    logout();
    toggleIsUserMenuVisible();
  }, [logout, toggleIsUserMenuVisible, setGoogleTag]);

  const handleSubscribeClick = useCallback(() => {
    setGoogleTag({ event: 'subscribe_click', source: 'profile' });

    openSubscriptionsModal(SHOULD_USE_TRIAL && userConfig.isTrialAvailable);
  }, [openSubscriptionsModal, setGoogleTag, userConfig]);

  const handleCancelClick = useCallback(() => {
    setGoogleTag({ event: 'subscription_cancel_click' });

    setIsCancelSubscriptionVisible(true);
  }, [setGoogleTag]);

  const handleCancelClose = useCallback(() => {
    setIsCancelSubscriptionVisible(false);
  }, []);

  useClickOutside(userMenuRef, () => setIsUserMenuVisible(false));

  const userName = user ? `${user.name} ${user.lastName}` : '';

  const paymentInfo = user ? getPaymentInfo(user) : null;

  return (
    <>
      <Container>
        <HeaderContainer>
          <HeaderInnerContainer>
            <LogoContainer>
              <NavLink to='/'>
                <LogoIcon />
              </NavLink>
            </LogoContainer>

            <NavigationContainer>
              <Navigation>
                {!isAuthorized && (
                  <TextButton onClick={handleLoginClick}>
                    <Text variant={TextVariants.Basic} as={TextAsVariants.Span}>
                      Log in
                    </Text>
                  </TextButton>
                )}

                <StyledButton onClick={handleDocumentsClick}>
                  <Text variant={TextVariants.BasicBold}>Solve problem</Text>
                </StyledButton>
              </Navigation>

              {user && (
                <div>
                  <UserMenuContainer ref={userMenuRef}>
                    <UserAvatarContainer>
                      <Avatar
                        fullName={userName}
                        size={USER_AVATAR_SIZE}
                        onClick={toggleIsUserMenuVisible}
                        image={user.picture}
                      />
                    </UserAvatarContainer>

                    {isUserMenuVisible && (
                      <UserMenu>
                        <UserProfileContainer>
                          <UserSubscriptionContainer>
                            {hasBasicPlan ? (
                              <>
                                <Text variant={TextVariants.BasicBold}>Basic</Text>

                                <Button onClick={handleSubscribeClick}>
                                  <Text variant={TextVariants.Basic}>Upgrade</Text>
                                </Button>
                              </>
                            ) : (
                              <>
                                <Text variant={TextVariants.BasicBold}>
                                  {user.subscriptionPlanName}
                                </Text>

                                {paymentInfo?.hasActiveSubscription && (
                                  <Button transparent onClick={handleCancelClick}>
                                    <Text variant={TextVariants.Basic}>Cancel</Text>
                                  </Button>
                                )}
                              </>
                            )}
                          </UserSubscriptionContainer>

                          {user && (
                            <UserContainer>
                              <Text variant={TextVariants.MiddleBold}>{user.email}</Text>
                            </UserContainer>
                          )}

                          {hasProPlan && paymentInfo && (
                            <SubscriptionDetails $isActive={paymentInfo.hasActiveSubscription}>
                              {paymentInfo.hasActiveSubscription && <CanceledIcon />}
                              {!paymentInfo.hasActiveSubscription && <ActiveIcon />}

                              <Text as={TextAsVariants.Span} variant={TextVariants.MiddleBold}>
                                {paymentInfo.text}
                              </Text>
                            </SubscriptionDetails>
                          )}
                        </UserProfileContainer>

                        <UserMenuItemMarked onClick={handleLogoutClick}>Log out</UserMenuItemMarked>
                      </UserMenu>
                    )}
                  </UserMenuContainer>
                </div>
              )}
            </NavigationContainer>
          </HeaderInnerContainer>
        </HeaderContainer>

        <InnerContainer id='page-content-container'>{children}</InnerContainer>
      </Container>

      {isCancelSubscriptionVisible && <CancelSubscriptionModal onClose={handleCancelClose} />}
    </>
  );
});

export { PageLayout };
