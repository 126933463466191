import styled from 'styled-components';

import { Modal } from 'modules/common/components/Modal';

export const StyledModal = styled(Modal)`
  width: 476px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.padding.base(4)};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;

  button {
    min-width: 120px;
  }

  @media (max-width: ${({ theme }) => theme.screen.mobile.sm}px) {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`;

export const CenteredText = styled.div`
  text-align: center;
`;
