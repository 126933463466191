export const getCurrencyLabel = (currency: string) => {
  switch (currency) {
    case 'usd':
      return 'US$';
    case 'cad':
      return 'CA$';
    case 'aud':
      return 'A$';
    case 'nzd':
      return 'NZ$';
    case 'sgd':
      return 'SG$';
    case 'gbp':
      return '£';
    case 'eur':
      return '€';
    default:
      return currency;
  }
};
