import { createGlobalStyle, css } from 'styled-components';

const auth0Styles = css`
  .auth0-lock-header-bg,
  .auth0-lock-name {
    display: none;
  }

  .auth0-lock-widget {
    padding: 12px;
  }

  .auth0-lock.auth0-lock .auth0-lock-center {
    @media (min-width: 481px) {
      padding: 0;
    }
  }

  .auth0-lock.auth0-lock .auth0-lock-form {
    padding: 24px 0;
  }

  .auth0-lock.auth0-lock .auth0-lock-widget {
    width: 360px;
  }

  .auth0-lock.auth0-lock .auth0-lock-terms {
    margin-bottom: 24px;
    border-radius: 10px;

    .auth0-lock-sign-up-terms-agreement label {
      display: flex;
    }
  }

  .auth0-lock.auth0-lock .auth0-lock-submit[disabled] {
  }

  .auth0-lock.auth0-lock .auth0-lock-submit {
    font-family: ${({ theme }) => theme.fonts.primary};
    cursor: pointer;
    font-weight: 600;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.12s ease-in-out 0s, filter 0.12s ease-in-out 0s;
    font-size: 22px;
    padding: 12px;
    text-transform: none;

    &:focus {
      box-shadow: none;
    }
  }

  .auth0-lock.auth0-lock .auth0-loading-container .auth0-loading {
    top: -2px;
  }

  .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error {
    border-radius: 10px;
  }

  .auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit {
    height: auto;
    line-height: initial;
  }

  .auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
    height: auto;
  }

  .auth0-lock .auth0-lock-header {
    display: none !important;
  }

  .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
    font-size: 16px;
  }
`;

const mjxStyles = css`
  mjx-container {
    mjx-math {
      max-width: 100%;
      text-wrap: wrap;
    }
  }
`;

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html {
    height: 100%;
  }
  
  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.colors.tittleText};
    height: 100%;

    &.size-locked {
      overflow: hidden;
    }
  }

  #root {
    height: 100%;
  }
  
  a {
    color: ${({ theme }) => theme.colors.greyAll};
    text-decoration: none;
  }

  ${mjxStyles}

  ${auth0Styles}

  .no-scroll { 
    overflow: hidden;
  }

  .ReactCrop {
    display: inline-block;

    @media (max-width: ${({ theme }) => theme.screen.tablet.sm}px) {
      overflow: hidden;
    }

    & .ReactCrop__child-wrapper {
      img {
        margin: 0 auto;
      }
    }
  }
`;
