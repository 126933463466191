import styled from 'styled-components';

import { Button } from 'modules/common/components/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 476px;
  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    max-width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  min-width: 215px;
`;
