export const getInitials = (fullName: string): string => {
  const strings = fullName.split(' ').map((value) => {
    const trimedValue = value.trim();

    if (trimedValue) {
      return trimedValue.charAt(0).toUpperCase();
    }

    return '';
  });

  return strings.join('');
};
