export const addDaysToDate = (daysCount: number): Date => {
  const date = new Date();

  date.setDate(date.getDate() + daysCount);

  return date;
};

export const formatDate = (datestamp: number) => {
  try {
    const date = new Date(datestamp * 1000);

    const { language } = navigator;

    return new Intl.DateTimeFormat(language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(date);
  } catch (error) {
    return null;
  }
};
