import { useCallback, useState } from 'react';

import { subscriptionsService } from 'core/subscriptions';

export const useCancelSubscription = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const makeRequest = useCallback(async () => {
    setIsFetching(true);

    await subscriptionsService.cancelSubscription();

    setIsFetching(false);
  }, []);

  return { isFetching, makeRequest };
};
