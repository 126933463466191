import { PaymentPeriod } from 'core/subscriptions/types';

const getPriceTitle = (period: PaymentPeriod, periodCount: number, isShort?: boolean) => {
  if (period === 'month') {
    if (periodCount === 1) {
      return isShort ? 'month' : 'Monthly';
    }
    if (periodCount === 6) {
      return isShort ? '6 months' : 'Twice a year';
    }

    return `Every ${periodCount} months`;
  }

  if (period === 'year') {
    if (periodCount === 1) {
      return isShort ? 'year' : 'Yearly';
    }

    return isShort ? `${periodCount} years` : `Every ${periodCount} years`;
  }

  return `Every ${periodCount} ${period}(s)`;
};

export { getPriceTitle };
