import { Price } from 'core/subscriptions/types';
import {
  getDiscountPercentage,
  getMonthlyPriceAmount,
  getPriceWithoutDiscount,
} from 'modules/price/utils';

import { DiscountData } from './types';

export const isMonthlySubscription = ({ period, periodCount }: Price): boolean =>
  period === 'month' && periodCount === 1;

export const sortPrices = (prices?: Price[]): Price[] | null => {
  if (!prices) {
    return null;
  }

  return [...prices].sort((prices1, prices2) => prices2.unitAmount - prices1.unitAmount);
};

export const getDiscountData = (price: Price, basePrice: Price | null): DiscountData => {
  const { unitAmount, period, periodCount } = price;

  const monthlyPrice = getMonthlyPriceAmount(unitAmount, period, periodCount);

  const discountPercentage =
    (monthlyPrice &&
      basePrice &&
      monthlyPrice <= basePrice?.unitAmount &&
      getDiscountPercentage(monthlyPrice, basePrice.unitAmount)) ||
    null;

  const isSignleMonthPrice = isMonthlySubscription(price);

  const priceWithoutDiscount =
    basePrice?.unitAmount && !isSignleMonthPrice
      ? getPriceWithoutDiscount(period, periodCount, basePrice.unitAmount)
      : null;

  return {
    monthlyPrice,
    discountPercentage,
    priceWithoutDiscount,
  };
};
