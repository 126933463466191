import { format } from 'date-fns';

import { UserProfile } from 'core/user/types';

export const getPaymentInfo = (user: UserProfile) => {
  if (user.activeTillDate) {
    const date = format(new Date(user.activeTillDate * 1000), 'MMM dd, yyy');

    return { text: `Cancels ${date}`, hasActiveSubscription: false };
  }

  if (user.paymentDate) {
    const date = format(new Date(user.paymentDate * 1000), 'MMM dd, yyy');

    return { text: `Next payment ${date}`, hasActiveSubscription: true };
  }

  return { text: '', hasActiveSubscription: false };
};
