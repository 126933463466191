import React, { memo } from 'react';

import { ReactComponent as InfoImage } from 'assets/images/info.svg';

import { Modal } from 'modules/common/components/Modal';
import { Text } from 'modules/common/components/Text';

import { PaymentResultStatuses } from '../../constants';

import { Props } from './types';
import { StyledButton, Wrapper } from './styles';

const PaymentStatusModal: React.FC<Props> = memo(({ status, onClose, onRetry }) => {
  const onPaymentRetry = () => {
    onRetry?.();
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Wrapper>
        <InfoImage />
        {status === PaymentResultStatuses.SUCCESS && <Text>Subscription successful!</Text>}

        {status === PaymentResultStatuses.FAILED && (
          <>
            <Text>Uh-oh!</Text>

            <Text>Your payment didn't go through. Let's give it another go?</Text>

            <StyledButton onClick={onPaymentRetry}>Retry</StyledButton>
          </>
        )}
      </Wrapper>
    </Modal>
  );
});

export { PaymentStatusModal };
