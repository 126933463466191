import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const setUpHttpClientAuth = (token?: string): void => {
  if (token) {
    httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    httpClient.defaults.headers.common.Authorization = undefined;
  }
};

let failedRequestsQueue: {
  resolve: (value: unknown) => void;
  reject: (reason: any) => void;
  config: InternalAxiosRequestConfig<any>;
}[] = [];

let isTokenRefreshing = false;

const setUpHttpClientAuthErrorInterceptor = (
  refreshToken: () => Promise<{ accessToken: string }>
): void => {
  httpClient.interceptors.response.use(undefined, async (error: AxiosError) => {
    const originalConfig: InternalAxiosRequestConfig & { retry?: boolean } = error.config!;

    if (error.response?.status === 401 && !originalConfig.retry) {
      if (!isTokenRefreshing) {
        originalConfig.retry = true;
        isTokenRefreshing = true;

        refreshToken()
          .then(({ accessToken }) => {
            setUpHttpClientAuth(accessToken);

            failedRequestsQueue.forEach((request) =>
              request.resolve(
                httpClient({
                  ...request.config,
                  headers: {
                    ...request.config.headers,
                    Authorization: `Bearer ${accessToken}`,
                  },
                })
              )
            );
          })
          .catch((tokenError) => {
            failedRequestsQueue.forEach((request) => request.reject(tokenError));
          })
          .finally(() => {
            failedRequestsQueue = [];
            isTokenRefreshing = false;
          });
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({ resolve, reject, config: error.config! });
      });
    }

    return Promise.reject(error);
  });
};

export { httpClient, setUpHttpClientAuth, setUpHttpClientAuthErrorInterceptor, AxiosError };
