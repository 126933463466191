import { useCallback } from 'react';
import TagManager from 'react-gtm-module';

import { GTM_ID } from 'config';

import { useUser } from 'modules/user/components/UserProvider/hooks';

import { GoogleTagPayload } from './types';

const useAnalytics = () => {
  const { user } = useUser();

  const setGoogleTag = useCallback(
    (eventPayload: GoogleTagPayload) => {
      const dataProvided: { email?: string } = {};

      if (user?.email) {
        dataProvided.email = user.email;
      }

      return TagManager.dataLayer({
        dataLayer: {
          ...eventPayload,
          ...dataProvided,
        },
      });
    },
    [user?.email]
  );

  const startTracking = useCallback(() => {
    if (GTM_ID) {
      TagManager.initialize({
        gtmId: GTM_ID,
      });
    }
  }, []);

  return { setGoogleTag, startTracking };
};

export { useAnalytics };
