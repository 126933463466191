import { PaymentPeriod } from 'core/subscriptions/types';

import { BadgeColor } from './types';

export const getDiscountColorByPeriod = (
  period: PaymentPeriod,
  periodCount: number
): BadgeColor => {
  if (period === 'year') {
    return BadgeColor.Primary;
  }

  if (period === 'month' && periodCount > 1) {
    return BadgeColor.Primary;
  }

  return BadgeColor.Transparent;
};
