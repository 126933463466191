import { MathJax3Config } from 'better-react-mathjax';

export const MATH_JAX_VERSION = 3;

export const MATH_JAX_CONFIG: MathJax3Config = {
  loader: { load: ['[tex]/html'] },
  tex: {
    packages: { '[+]': ['html'] },
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]'],
    ],
  },
};
