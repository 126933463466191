import React from 'react';

export enum TextAsVariants {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  P = 'p',
  Div = 'div',
  Span = 'span',
}

export enum TextVariants {
  H1Medium = 'h1Medium',
  H1Bold = 'h1Bold',
  H1Heavy = 'h1Heavy',

  H2Medium = 'h2Medium',
  H2Bold = 'h2Bold',
  H2Heavy = 'h2Heavy',

  PTitleMedium = 'pTitleMedium',
  PTitleBold = 'pTitleBold',
  PTitleHeavy = 'pTitleHeavy',

  ChatTitleMedium = 'chatTitleMedium',
  ChatTitleBold = 'chatTitleBold',
  ChatTitleHeavy = 'chatTitleHeavy',

  Body = 'body',
  BodySemibold = 'bodySemibold',
  BodyBold = 'bodyBold',

  Basic = 'basic',
  BasicBold = 'basicBold',
  BasicHeavy = 'basicHeavy',

  Middle = 'middle',
  MiddleBold = 'middleBold',
  MiddleHeavy = 'middleHeavy',

  Mini = 'mini',
  MiniMedium = 'miniMedium',
  MiniBold = 'miniBold',
}

export type Props = React.PropsWithChildren & {
  variant?: TextVariants;
  as?: TextAsVariants;
};
