import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';

import { UserProfile, UserAllowedActionsConfig, UserConfig } from 'core/user/types';
import { userService } from 'core/user';
import { useAuth } from 'modules/user/components/AuthProvider/hooks';
import { Token } from 'modules/user/components/AuthProvider/types';

import { UserContextProps } from './types';

export const UserContext = createContext<UserContextProps>({} as UserContextProps);

const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<UserProfile | null>(null);

  const [allowedActionsConfig, setAllowedActionsConfig] = useState<UserAllowedActionsConfig>(() =>
    userService.getUserAllowedActionsConfig()
  );

  const [userConfig, setUserConfig] = useState<UserConfig>(() => userService.getUserConfig());

  const [isFetching, setFetching] = useState(false);

  const { token, resetToken } = useAuth();

  const prevTokenRef = useRef<Token>(null);

  const updateAllowedActionsConfig = useCallback(
    (config: Partial<UserAllowedActionsConfig>) => {
      const nextConfig = {
        ...allowedActionsConfig,
        ...config,
      };

      setAllowedActionsConfig(nextConfig);

      userService.setUserAllowedActionsConfig(nextConfig);
    },
    [allowedActionsConfig]
  );

  const updateUserConfig = useCallback(
    (config: Partial<UserConfig>) => {
      const nextConfig = {
        ...userConfig,
        ...config,
      };

      setUserConfig(nextConfig);

      userService.setUserConfig(nextConfig);
    },
    [userConfig]
  );

  const value = useMemo<UserContextProps>(
    () => ({
      user,
      isFetching,
      setUser,
      setAllowedActionsConfig: updateAllowedActionsConfig,
      hasBasicPlan: user?.subscriptionPlanName === 'Basic' || !user,
      hasProPlan: user?.subscriptionPlanName === 'Pro',
      allowedActionsConfig,
      userConfig,
      setUserConfig: updateUserConfig,
    }),
    [
      user,
      isFetching,
      allowedActionsConfig,
      updateAllowedActionsConfig,
      userConfig,
      updateUserConfig,
    ]
  );

  useEffect(() => {
    if (token && token.accessToken !== prevTokenRef.current?.accessToken) {
      setFetching(true);

      userService
        .fetchUser()
        .then(setUser)
        .catch(resetToken)
        .finally(() => {
          setFetching(false);
        });
    }

    if (!token && prevTokenRef.current?.accessToken) {
      setUser(null);
      setFetching(false);
    }

    prevTokenRef.current = token;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserProvider };
