import styled from 'styled-components';

export const StyledButton = styled.button<{ $transparent?: boolean; $disabled?: boolean }>`
  border: 0;
  position: relative;
  border-radius: 50px;
  color: ${({ theme, $transparent }) =>
    $transparent ? theme.colors.paynesGrey : theme.colors.white};
  background-color: ${({ theme, $transparent }) =>
    $transparent ? 'transparent' : theme.colors.main1};
  padding: ${({ theme }) => `${theme.padding.base(1)} ${theme.padding.base(2)}`};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
`;

export const ContentContainer = styled.div<{ $isFetching?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.padding.base(1.5)};
  opacity: ${({ $isFetching }) => ($isFetching ? 0 : 1)};
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
