import React, { memo } from 'react';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

import { MATH_JAX_CONFIG, MATH_JAX_VERSION } from './constants';

const MathJaxProvider: React.FC<React.PropsWithChildren> = memo(({ children }) => {
  return (
    <MathJaxContext version={MATH_JAX_VERSION} config={MATH_JAX_CONFIG}>
      {children}
    </MathJaxContext>
  );
});

export { MathJaxProvider, MathJax };
