import { useEffect, useState } from 'react';

import { Price } from 'core/subscriptions/types';

import { usePrices } from './usePrices';

export const useSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<Price[]>([]);
  const [country, setCountry] = useState<string | null>(null);

  const { data: pricing, isFetching } = usePrices();

  useEffect(() => {
    if (pricing) {
      const sorted = pricing.prices.sort((a, b) => a.unitAmount - b.unitAmount);

      setSubscriptions(
        sorted.map((price: Price) => ({
          id: price.id,
          unitAmount: price.unitAmount,
          period: price.period,
          currency: price.currency,
          periodCount: price.periodCount,
        }))
      );

      setCountry(pricing.countryCode);
    }
  }, [pricing]);

  return {
    isFetching,
    subscriptions,
    country,
  };
};
