import styled, { css } from 'styled-components';

import { TextVariants } from './types';

const h1Styles = css`
  font-size: 42px;
  line-height: 46px;

  @media (max-width: ${({ theme }) => theme.screen.desktop.md}px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const h1MediumStyles = css`
  ${h1Styles}

  font-weight: 500;
`;

const h1BoldStyles = css`
  ${h1Styles}

  font-weight: 700;
`;

const h1HeavyStyles = css`
  ${h1Styles}

  font-weight: 900;
`;

const h2Styles = css`
  font-size: 36px;
  line-height: 42px;

  @media (max-width: ${({ theme }) => theme.screen.desktop.md}px) {
    font-size: 26px;
    line-height: 34px;
  }
`;

const h2MediumStyles = css`
  ${h2Styles}

  font-weight: 500;
`;

const h2BoldStyles = css`
  ${h2Styles}

  font-weight: 700;
`;

const h2HeavyStyles = css`
  ${h2Styles}

  font-weight: 900;
`;

const pTitleStyles = css`
  font-size: 28px;
  line-height: 36px;
`;

const pTitleMediumStyles = css`
  ${pTitleStyles}

  font-weight: 500;
`;

const pTitleBoldStyles = css`
  ${pTitleStyles}

  font-weight: 700;
`;

const pTitleHeavyStyles = css`
  ${pTitleStyles}

  font-weight: 900;
`;

const chatTitleStyles = css`
  font-size: 24px;
  line-height: 24px;
`;

const chatTitleMediumStyles = css`
  ${chatTitleStyles}

  font-weight: 500;
`;

const chatTitleBoldStyles = css`
  ${chatTitleStyles}

  font-weight: 700;
`;

const chatTitleHeavyStyles = css`
  ${chatTitleStyles}

  font-weight: 900;
`;

const bodyStyles = css`
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;

  @media (max-width: ${({ theme }) => theme.screen.desktop.md}px) {
    font-size: 16px;
    line-height: 23px;
  }
`;

const bodySemiboldStyles = css`
  ${bodyStyles}

  font-weight: 600;
`;

const bodyBoldStyles = css`
  ${bodyStyles}

  font-weight: 700;
`;

export const basicStyles = css`
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
`;

export const basicBoldStyles = css`
  ${basicStyles}

  font-weight: 700;
`;

const basicHeavyStyles = css`
  ${basicStyles}

  font-weight: 900;
`;

export const middleStyles = css`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`;

const middleBoldStyles = css`
  ${middleStyles}

  font-weight: 700;
`;

const middleHeavyStyles = css`
  ${middleStyles}

  font-weight: 900;
`;

const miniStyles = css`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
`;

const miniMediumStyles = css`
  ${miniStyles}

  font-weight: 500;
`;

const miniBoldStyles = css`
  ${miniStyles}

  font-weight: 700;
`;

const stylesByVariant = {
  [TextVariants.H1Medium]: h1MediumStyles,
  [TextVariants.H1Bold]: h1BoldStyles,
  [TextVariants.H1Heavy]: h1HeavyStyles,

  [TextVariants.H2Medium]: h2MediumStyles,
  [TextVariants.H2Bold]: h2BoldStyles,
  [TextVariants.H2Heavy]: h2HeavyStyles,

  [TextVariants.PTitleMedium]: pTitleMediumStyles,
  [TextVariants.PTitleBold]: pTitleBoldStyles,
  [TextVariants.PTitleHeavy]: pTitleHeavyStyles,

  [TextVariants.ChatTitleMedium]: chatTitleMediumStyles,
  [TextVariants.ChatTitleBold]: chatTitleBoldStyles,
  [TextVariants.ChatTitleHeavy]: chatTitleHeavyStyles,

  [TextVariants.Body]: bodyStyles,
  [TextVariants.BodySemibold]: bodySemiboldStyles,
  [TextVariants.BodyBold]: bodyBoldStyles,

  [TextVariants.Basic]: basicStyles,
  [TextVariants.BasicBold]: basicBoldStyles,
  [TextVariants.BasicHeavy]: basicHeavyStyles,

  [TextVariants.Middle]: middleStyles,
  [TextVariants.MiddleBold]: middleBoldStyles,
  [TextVariants.MiddleHeavy]: middleHeavyStyles,

  [TextVariants.Mini]: miniStyles,
  [TextVariants.MiniMedium]: miniMediumStyles,
  [TextVariants.MiniBold]: miniBoldStyles,
};

export const StyledText = styled.p<{ $variant: TextVariants }>`
  ${({ $variant }) => stylesByVariant[$variant]}
`;
