import React, { useState } from 'react';

import { userService } from 'core/user';

import { Button } from 'modules/common/components/Button';
import { Text, TextVariants } from 'modules/common/components/Text';
import { useUser } from 'modules/user/components/UserProvider/hooks';
import { useCancelSubscription } from 'modules/price/components/Subscriptions/hooks/useCancelSubscription';
import { useAnalytics } from 'modules/common/hooks/useAnalytics';

import { Props } from './types';
import { ButtonsWrapper, StyledModal, CenteredText } from './styles';

const CancelSubscriptionModal: React.FC<Props> = ({ onClose }) => {
  const { setUser } = useUser();
  const [isCanceled, setIsCancelled] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { makeRequest } = useCancelSubscription();
  const { setGoogleTag } = useAnalytics();

  const onCancel = async () => {
    try {
      setIsFetching(true);

      setGoogleTag({ event: 'cancel_subscription' });

      await makeRequest();

      setUser(await userService.fetchUser());
      setIsCancelled(true);
    } finally {
      setIsFetching(false);
    }
  };

  if (isCanceled) {
    return (
      <StyledModal onClose={onClose}>
        <CenteredText>
          <Text variant={TextVariants.PTitleBold}>Subscription cancelled</Text>
        </CenteredText>

        <ButtonsWrapper>
          <Button onClick={onClose}>
            <Text variant={TextVariants.Basic}>Got it</Text>
          </Button>
        </ButtonsWrapper>
      </StyledModal>
    );
  }

  return (
    <StyledModal onClose={onClose}>
      <CenteredText>
        <Text variant={TextVariants.PTitleBold}>Sure about cancelling?</Text>
      </CenteredText>

      <CenteredText>
        <Text variant={TextVariants.Body}>You'll miss all the cool stuff!</Text>
      </CenteredText>

      <ButtonsWrapper>
        <Button transparent onClick={onCancel} isFetching={isFetching} disabled={isFetching}>
          <Text variant={TextVariants.Basic}>Okay, cancel</Text>
        </Button>
        <Button autoFocus disabled={isFetching} onClick={onClose}>
          <Text variant={TextVariants.Basic}>Wait, keep it</Text>
        </Button>
      </ButtonsWrapper>
    </StyledModal>
  );
};

export { CancelSubscriptionModal };
