import styled from 'styled-components';

const CLOSE_BUTTON_SIZE = 30;

export const Wrapper = styled.div``;

export const Overlay = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.modalOverlay};
  padding: ${({ theme }) => theme.padding.base(2)};
  z-index: 1000;
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 1.70157px 15.3142px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-height: 100%;
  overflow-y: auto;
  padding: ${({ theme }) => theme.padding.base(2.5)};
  position: relative;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.padding.base(2)};
`;

export const CloseButton = styled.div`
  width: ${CLOSE_BUTTON_SIZE}px;
  height: ${CLOSE_BUTTON_SIZE}px;
  border-radius: ${CLOSE_BUTTON_SIZE}px;
  background-color: ${({ theme }) => theme.utils.hexToRgba(theme.colors.greyIcon, 0.36)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
