import { useEffect, memo } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';

import { ReactComponent as CookiesIcon } from 'assets/images/cookies.svg';

import { ConsentRequiredCountries } from 'constants/consent';

import { useAnalytics } from 'modules/common/hooks/useAnalytics';
import { usePrices } from 'modules/price/components/Subscriptions/hooks/usePrices';

import { AgreeButton } from './components/AgreeButton';
import { StyledContainer, Text } from './styles';
import styles from './styles.module.css';

const Consent = memo(() => {
  const { data, isFetching } = usePrices();
  const { startTracking } = useAnalytics();

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!(data.countryCode in ConsentRequiredCountries) || getCookieConsentValue()) {
      startTracking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isFetching || !data || !(data.countryCode in ConsentRequiredCountries)) {
    return null;
  }

  return (
    <CookieConsent
      disableStyles
      containerClasses={styles.container}
      buttonWrapperClasses={styles.buttonContainer}
      ButtonComponent={AgreeButton}
      location='none'
      onAccept={startTracking}
    >
      <StyledContainer>
        <CookiesIcon />
        <Text>
          We use cookies to offer you a better browsing experience, analyze site traffic,
          personalize content, and serve targeted advertisements.
          <br />
          <br />
          Read about how we use cookies and how you can control them on our{' '}
          <a href='/privacy' target='_blank' rel='noreferrer'>
            Privacy Policy and Cookie Policy
          </a>
          <br />
          <br />
          By using this site, you consent to our use of cookies.
          <br />
          <br />I Agree.
        </Text>
      </StyledContainer>
    </CookieConsent>
  );
});

export { Consent };
