import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState, memo } from 'react';

import { setUpHttpClientAuth, setUpHttpClientAuthErrorInterceptor } from 'services/http-client';

import { useAnalytics } from 'modules/common/hooks/useAnalytics';

import { AuthModal } from './components/AuthModal';

import { lockService } from './services/LockService';

import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY } from './constants';
import { handleExtensibilityError, cleanUrlHash, getToken } from './utils';
import {
  Token,
  CbType,
  AuthContextType,
  IToken,
  AuthModalParams,
  AuthTypes,
  AuthModalPayload,
} from './types';

export const AuthContext = React.createContext<AuthContextType>(
  undefined as unknown as AuthContextType
);

const AuthProvider: React.FC<PropsWithChildren> = memo(({ children }) => {
  const { setGoogleTag } = useAnalytics();

  const [token, setToken] = useState<Token>(getToken);
  const [isReady, setIsReady] = useState(false);
  const [modalParams, setModalParams] = useState<AuthModalParams | null>(null);

  const onAuth = useCallback(
    ({ accessToken, idToken }: IToken) => {
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      localStorage.setItem(ID_TOKEN_KEY, idToken);

      setUpHttpClientAuth(accessToken);

      setToken({ accessToken, idToken });

      setGoogleTag({ event: modalParams?.type === 'login' ? 'login' : 'signup' });

      setModalParams(null);
    },
    [modalParams, setGoogleTag]
  );

  const signIn = useCallback((params: AuthModalPayload = {}) => {
    setModalParams({ type: AuthTypes.Login, ...params });
  }, []);

  const signUp = useCallback((params: AuthModalPayload = {}) => {
    setModalParams({ type: AuthTypes.SignUp, ...params });
  }, []);

  const resetToken = useCallback(() => {
    setToken(null);

    setUpHttpClientAuth(undefined);

    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ID_TOKEN_KEY);
  }, []);

  const logout = useCallback(() => {
    resetToken();

    lockService.logout({ returnTo: window.location.origin });
  }, [resetToken]);

  const handleAuthClose = useCallback((skipLock?: boolean) => {
    setModalParams((prev) => {
      if (prev?.shouldLock && !skipLock) {
        window.location.href = '/';
      }

      return null;
    });

    lockService.hide();
  }, []);

  const lockOnAuthListener = useCallback((cb: CbType) => {
    lockService.on('authenticated', cb);

    return () => {
      lockService.off('authenticated', cb);
    };
  }, []);

  const value = useMemo(
    () => ({
      logout,
      signIn,
      signUp,
      close: handleAuthClose,
      token,
      resetToken,
      isAuthOpened: !!modalParams,
      lockOnAuthListener,
      isAuthorized: !!token,
    }),
    [logout, signIn, signUp, handleAuthClose, token, resetToken, modalParams, lockOnAuthListener]
  );

  // Auto select terms checkbox in Lock
  useEffect(() => {
    const checkForm = () => {
      const checkbox = document.querySelector(
        '.auth0-lock-sign-up-terms-agreement input[type="checkbox"]'
      ) as HTMLInputElement | null;

      if (checkbox && !checkbox.checked) {
        checkbox.click();
      }
    };

    lockService.on('show', checkForm);

    return () => {
      lockService.off('show', checkForm);
    };
  }, []);

  useEffect(() => {
    lockService.on('authenticated', onAuth);

    const refreshToken = async (): Promise<AuthResult> => {
      return new Promise((resolve, reject) => {
        lockService.checkSession({}, (error, authResult) => {
          if (error) {
            resetToken();

            // clearLocalEssayData();

            reject(error);
          }

          if (authResult) {
            const { accessToken, idToken } = authResult;
            localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
            localStorage.setItem(ID_TOKEN_KEY, idToken);

            setUpHttpClientAuth(accessToken);

            setToken({ accessToken, idToken });

            resolve(authResult);
          }
        });
      });
    };

    setUpHttpClientAuthErrorInterceptor(refreshToken);

    if (token) {
      cleanUrlHash();
    }

    setIsReady(true);

    return () => {
      lockService.off('authenticated', onAuth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAuthChange = useCallback(() => {
    handleAuthClose(true);

    if (modalParams?.type === 'login') {
      signUp({
        shouldLock: modalParams.shouldLock,
        redirectTo: modalParams.redirectTo,
      });
    } else if (modalParams?.type === 'signUp') {
      signIn({
        shouldLock: modalParams.shouldLock,
        redirectTo: modalParams.redirectTo,
      });
    }
  }, [
    handleAuthClose,
    modalParams?.type,
    modalParams?.shouldLock,
    modalParams?.redirectTo,
    signUp,
    signIn,
  ]);

  const handleAuthShow = useCallback(() => {
    setGoogleTag({ event: 'show_auth_form' });

    if (!modalParams) {
      return;
    }

    lockService.show({
      initialScreen: modalParams.type,
      allowLogin: modalParams.type === 'login',
      allowSignUp: modalParams.type === 'signUp',
    });
  }, [modalParams, setGoogleTag]);

  useEffect(() => {
    setUpHttpClientAuth(token?.accessToken);
  }, [token?.accessToken]);

  // handle extensibility errors
  useEffect(() => {
    lockService.on('signup error', handleExtensibilityError);

    return () => {
      lockService.off('signup error', handleExtensibilityError);
    };
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}

      {modalParams && (
        <AuthModal
          type={modalParams.type}
          onChange={handleAuthChange}
          onShow={handleAuthShow}
          onClose={handleAuthClose}
          renderHeader={modalParams.renderHeader}
        />
      )}
      {/* {hasError && <ErrorModal onClose={() => setError(false)} />} */}
    </AuthContext.Provider>
  );
});

export { AuthProvider };
