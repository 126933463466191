import styled, { css } from 'styled-components';

import { Row } from 'modules/common/components/Row';
import { Button } from 'modules/common/components/Button';

export const PAGE_LAYOUT_HEADER_MIN_HEIGHT = 80;

export const USER_AVATAR_SIZE = 40;

export const Container = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const InnerContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.width.contentMaxWidth}px;
  height: calc(100vh - ${PAGE_LAYOUT_HEADER_MIN_HEIGHT}px);
  overflow-y: hidden;
  margin: 0 auto;
  position: relative;

  &.page-content-container-without-fixed-size {
    height: auto;
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.screen.desktop.md}px) {
    max-width: 100%;
  }
`;

export const HeaderContainer = styled.header`
  width: 100%;
  box-shadow: 0px 2px 2px 0px rgba(169, 169, 169, 0.25);
`;

export const HeaderInnerContainer = styled(Row).attrs({ $justifyContent: 'space-between' })`
  min-height: ${PAGE_LAYOUT_HEADER_MIN_HEIGHT}px;
  max-width: ${({ theme }) => theme.width.contentMaxWidth}px;
  padding: ${({ theme }) => theme.padding.base(2.5)} 0;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.screen.desktop.md}px) {
    max-width: 100%;
    padding: 0px ${({ theme }) => theme.padding.base(2)};
  }
`;

export const LogoContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.screen.tablet.md}px) {
    & svg {
      width: 83px;
    }
  }

  @media (max-width: ${({ theme }) => theme.screen.mobile.md}px) {
    & svg {
      width: 63px;
    }
  }
`;

export const NavigationContainer = styled(Row)`
  gap: ${({ theme }) => theme.padding.base(7.25)};

  @media (max-width: ${({ theme }) => theme.screen.desktop.md}px) {
    gap: ${({ theme }) => theme.padding.base(3)};
  }
`;

export const Navigation = styled(Row)`
  gap: ${({ theme }) => theme.padding.base(7.25)};

  @media (max-width: ${({ theme }) => theme.screen.desktop.md}px) {
    gap: ${({ theme }) => theme.padding.base(3)};
  }

  @media (max-width: ${({ theme }) => theme.screen.mobile.md}px) {
    gap: ${({ theme }) => theme.padding.base(1.5)};
  }

  & a.active {
    color: ${({ theme }) => theme.colors.main1};
  }
`;

export const TextButton = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.greyAll};
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.accentv1};
`;

export const UserMenuContainer = styled.div`
  position: relative;
`;

export const UserMenu = styled.div`
  cursor: default;
  position: absolute;
  display: flex;
  gap: ${({ theme }) => theme.padding.base(2)};
  top: 100%;
  right: 0;
  width: 300px;
  z-index: 100;
  transform: translateY(${({ theme }) => theme.padding.base(1)});
  padding: ${({ theme }) => `${theme.padding.base(1.5)} ${theme.padding.base(2)}`};
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 2px 0px rgba(169, 169, 169, 0.25);
`;

export const UserAvatarContainer = styled.div`
  cursor: pointer;
`;

export const UserMenuItem = styled.div`
  cursor: pointer;
`;

export const UserMenuItemMarked = styled(UserMenuItem)`
  color: ${({ theme }) => theme.colors.accentv1};
`;

export const UserProfileContainer = styled.div`
  padding: ${({ theme }) => theme.padding.base(1.5)};
  background-color: ${({ theme }) => theme.colors.siteAccent1};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.padding.base(1)};
`;

export const UserSubscriptionContainer = styled(Row).attrs({
  $justifyContent: 'space-between',
  $gap: (theme) => theme.padding.base(1),
})``;

export const UserContainer = styled.div`
  color: ${({ theme }) => theme.colors.paynesGrey};
`;

const subscriptionActiveStyles = css`
  background-color: ${({ theme }) => theme.utils.hexToRgba(theme.colors.status.success, 0.15)};
  color: ${({ theme }) => theme.colors.status.success};

  svg {
    fill: ${({ theme }) => theme.colors.status.success};
  }
`;

const subscriptionCanceledStyles = css`
  background-color: ${({ theme }) => theme.utils.hexToRgba(theme.colors.status.warning, 0.15)};
  color: ${({ theme }) => theme.colors.status.warning};

  svg {
    fill: ${({ theme }) => theme.colors.status.warning};
  }
`;

export const SubscriptionDetails = styled.div<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: ${({ theme }) => theme.padding.base(0.25)} ${({ theme }) => theme.padding.base(0.75)};
  border-radius: 4px;

  ${({ $isActive }) => ($isActive ? subscriptionActiveStyles : subscriptionCanceledStyles)}

  svg {
    width: 15px;
    height: auto;
    margin-right: ${({ theme }) => theme.padding.base(0.5)};
  }
`;
