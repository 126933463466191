import { v4 as uuidv4 } from 'uuid';

import { UserProfile, UserAllowedActionsConfig, UserConfig } from './types';

export const BASE_URL = '/users';

export const AI_USER: UserProfile = {
  id: uuidv4(),
  email: '',
  name: 'Artificial',
  lastName: 'Intelligence',
  subscriptionPlanName: 'Basic',
  isSubscriptionTrial: false,
  isSystemUser: true,
};

export const DEFAULT_USER: UserProfile = {
  id: uuidv4(),
  email: '',
  name: 'U',
  lastName: '',
  subscriptionPlanName: 'Basic',
  isSubscriptionTrial: false,
};

export const USER_ALLOWED_ACTIONS_CONFIG_STORAGE_KEY = 'userAllowedActionsConfig';

export const USER_ALLOWED_ACTIONS_CONFIG_DEFAULT: UserAllowedActionsConfig = {
  stepsPercentage: 60,
  solutionsCount: 3,
};

export const USER_CONFIG_STORAGE_KEY = 'userConfig';

export const USER_CONFIG_DEFAULT: UserConfig = {
  isTrialAvailable: true,
};
