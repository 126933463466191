import { PaymentPeriod } from 'core/subscriptions/types';

const getMonthlyPriceAmount = (
  price: number,
  period: PaymentPeriod,
  periodCount: number
): number | null => {
  if (period === 'year') {
    return Math.floor(((price / (periodCount * 12)) * 100) / 100);
  }

  if (period === 'month') {
    if (periodCount === 1) {
      return price;
    }

    return Math.floor(((price / periodCount) * 100) / 100);
  }

  return null;
};

export { getMonthlyPriceAmount };
