import { PaymentPeriod } from 'core/subscriptions/types';

const getPriceWithoutDiscount = (
  period: PaymentPeriod,
  periodCount: number,
  basicMonthlyPrice: number
): number => {
  let monthsCount: number = 0;

  if (period === 'year') {
    monthsCount = periodCount * 12;
  } else if (period === 'month') {
    monthsCount = periodCount;
  }

  return Math.floor((monthsCount * basicMonthlyPrice * 100) / 100);
};

export { getPriceWithoutDiscount };
