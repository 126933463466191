/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useLayoutEffect, memo } from 'react';

import { Text, TextVariants, TextAsVariants } from 'modules/common/components/Text';

import { Props } from './types';
import { getTitle, getText, getAnotherAuth } from './utils';
import { StyledModal, ChangeTypeText, ChangeTypeContainer } from './styles';

const AuthModal: React.FC<Props> = memo(({ onClose, onShow, onChange, type, renderHeader }) => {
  useLayoutEffect(() => {
    onShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <StyledModal onClose={onClose}>
      {renderHeader ? (
        renderHeader(type)
      ) : (
        <Text variant={TextVariants.PTitleHeavy}>{getTitle(type)}</Text>
      )}

      <ChangeTypeContainer>
        <Text as={TextAsVariants.Span}>{getText(type)}</Text>{' '}
        <ChangeTypeText onClick={onChange}>
          <Text>{getAnotherAuth(type)}</Text>
        </ChangeTypeText>
      </ChangeTypeContainer>

      <div id='auth0-lock-container' />
    </StyledModal>
  );
});

export { AuthModal };
