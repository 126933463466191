import { DefaultTheme } from 'styled-components';
import hexToRgba from 'hex-to-rgba';

const PADDING_BASE = 8;

const screen = {
  sm: 1024,
  desktop: {
    lg: 1440,
    md: 1170,
    sm: 1024,
    xsm: 959,
  },
  tablet: {
    lg: 820,
    md: 744,
    sm: 639,
  },
  mobile: {
    sm: 320,
    md: 374,
    lg: 412,
    xlg: 479,
  },
};

export const theme: DefaultTheme = {
  colors: {
    accentv1: '#F17149',
    main1: '#77A6F7',
    background: '#F9FAFF',
    white: '#ffffff',
    black: '#000000',
    siteAccent1: '#EFF3FE',
    supplementary: '#F6F6FA',
    tittleText: '#191919',
    bodyText: '#555454',
    contour: '#828282',
    grey: '#818283',
    lightGrey: '#EBF0FF',
    dayLightGrey: '#D4D4D4',
    anotherLightGrey: '#A3A3A3',
    greyAll: '#5D5D5D',
    greyIcon: '#ACACAC',
    chatError: '#FFC1C1',
    modalOverlay: 'rgba(36, 39, 48, 0.7)',
    darkGrey: '#303030',
    dayGreyAverage: '#BDBDBD',
    paynesGrey: '#555B6E',
    dayGrey: '#676767',
    accentDarkGrey: '#333333',
    deepGrey: '#747474',
    realGrey: '#B1B1B1',
    lightGreyInput: '#E1DEDE',
    aithorBlack: '#262626',
    textSecondary: 'rgba(60, 60, 67, 0.85)',
    anotherBlack: '#212121',

    status: {
      success: '#27C934',
      warning: '#F29E50',
      error: '#E94949',
    },
  },
  fonts: {
    primary: "'Mulish', sans-serif",
  },
  padding: {
    base: (value: number) => `${value * PADDING_BASE}px`,
    baseNumber: (value: number) => value * PADDING_BASE,
  },
  utils: {
    hexToRgba,
  },
  screen,
  width: {
    contentMaxWidth: 1170,
  },
};
