// TODO: move to core ?

export enum PaymentResultStatuses {
  FAILED = 'failed',
  SUCCESS = 'succeeded',
  EXISTS = 'exists',
}

export enum StripeResultEnum {
  FAILED = 'failed',
  SUCCESS = 'success',
  REQUIRED_3DS = '3ds-required',
}

export const ACTIVE_SUBSCRIPTION_EXISTS_STATUS = 409;
