import styled, { css } from 'styled-components';

import { Row } from 'modules/common/components/Row';
import { Text, TextVariants } from 'modules/common/components/Text';

import { BadgeColor } from './types';

export const PriceContainer = styled.div``;

export const PriceTitle = styled(Text).attrs({ variant: TextVariants.BodyBold })`
  color: ${({ theme }) => theme.colors.paynesGrey};

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    font-size: 18px;
  }
`;

export const PriceTitleExtended = styled(Text).attrs({ variant: TextVariants.Middle })`
  color: ${({ theme }) => theme.colors.dayGrey};
`;

export const PriceSubtitle = styled.div`
  font-size: 14px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.dayGreyAverage};
`;

const priceBadgePrimaryColor = css`
  background-color: ${({ theme }) => theme.colors.main1};
  color: ${({ theme }) => theme.colors.white};
`;

const priceBadgeTransparentStyles = css`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text};
  padding: 5px 0;
`;

const priceBadgeStylesByType = {
  primary: priceBadgePrimaryColor,
  transparent: priceBadgeTransparentStyles,
};

export const PriceBadge = styled.div<{ $color: BadgeColor }>`
  padding: ${({ theme }) => theme.padding.base(0.5)} ${({ theme }) => theme.padding.base(1)};
  border-radius: 50px;
  ${({ $color }) => priceBadgeStylesByType[$color]}
`;

export const PriceOptionContainer = styled(Row)`
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  margin-bottom: 6px;
  cursor: pointer;
`;

export const PriceOptionRadioButton = styled.div<{ $selected: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: border 0.2s linear;
  border: ${({ theme, $selected }) =>
    $selected ? `5px solid ${theme.colors.main1}` : `1px solid ${theme.colors.dayGreyAverage}`};
`;

export const NormalText = styled(Text)`
  font-weight: 400;
`;
