export enum Routes {
  Home = '',
  Solver = 'solver',
  Privacy = 'privacy-policy',
  Terms = 'terms-of-service',
}

export enum RouteIds {
  Home = 'home',
  Solver = 'solver',
  SolverDocument = 'solver-document',
  Privacy = 'privacy-policy',
  Terms = 'terms-of-service',
}
