import { useEffect, useState } from 'react';

import { subscriptionsService } from 'core/subscriptions';
import { PricesResponse } from 'core/subscriptions/types';

export const usePrices = () => {
  const [data, setData] = useState<PricesResponse | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    const fetchPrices = async () => {
      setIsFetching(true);

      const response = await subscriptionsService.fetchPrices();

      setData(response);
      setIsFetching(false);
    };

    fetchPrices();
  }, []);

  return { data, isFetching };
};
