import React from 'react';
import { DataRouteObject } from 'react-router-dom';

import { RouteIds, Routes } from './constants';

const HomePage = React.lazy(() => import('pages/Home'));

const DocumentsPage = React.lazy(() => import('pages/Documents'));

const SolverPage = React.lazy(() => import('pages/Solver'));

const PrivacyPage = React.lazy(() => import('pages/Privacy'));

const TermsPage = React.lazy(() => import('pages/Terms'));

const routes: DataRouteObject[] = [
  {
    id: RouteIds.Home,
    path: Routes.Home,
    element: <HomePage />,
  },
  {
    id: RouteIds.Solver,
    path: Routes.Solver,
    element: <DocumentsPage />,
  },
  {
    id: RouteIds.SolverDocument,
    path: `${Routes.Solver}/:id`,
    element: <SolverPage />,
  },
  {
    id: RouteIds.Privacy,
    path: Routes.Privacy,
    element: <PrivacyPage />,
  },
  {
    id: RouteIds.Terms,
    path: Routes.Terms,
    element: <TermsPage />,
  },
];

export { routes };
