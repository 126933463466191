import { useMemo, useRef } from 'react';

import { theme } from 'modules/common/containers/ThemeProvider';

import { WindowSize } from './types';

export const useWindowSize = () => {
  const windowSize = useRef<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  }).current;

  const isTwoToOneRatio = useMemo(
    () =>
      Boolean(
        windowSize.width &&
          windowSize.height &&
          windowSize.width / windowSize.height >= 2.05 &&
          windowSize.height < 600
      ),
    [windowSize.height, windowSize.width]
  );

  const isMobile = useMemo(
    () => Boolean(windowSize.width && windowSize.width <= theme.screen.sm),
    [windowSize.width]
  );

  const isTablet = useMemo(
    () => Boolean(windowSize.width && windowSize.width <= theme.screen.tablet.sm),
    [windowSize.width]
  );

  const isTabletMd = useMemo(
    () => Boolean(windowSize.width && windowSize.width <= theme.screen.tablet.md),
    [windowSize.width]
  );

  const isDesktopMd = useMemo(
    () => Boolean(windowSize.width && windowSize.width <= theme.screen.desktop.md),
    [windowSize.width]
  );

  return {
    windowSize,
    isMobile,
    isTablet,
    isTabletMd,
    isDesktopMd,
    isTwoToOneRatio,
  };
};
