import React from 'react';

import { Props } from './types';
import { StyledText } from './styles';

const CrossedOutText: React.FC<Props> = ({ children, color }) => {
  return <StyledText $color={color}>{children}</StyledText>;
};

export { CrossedOutText };
