import { memo, forwardRef } from 'react';

import { theme } from 'modules/common/containers/ThemeProvider';

import { Loader } from 'modules/common/components/Loader';

import { Props } from './types';
import { StyledButton, ContentContainer, LoaderContainer } from './styles';

const Button = memo(
  forwardRef<HTMLButtonElement, Props>(
    ({ children, isFetching, transparent, disabled, ...props }, ref) => {
      return (
        <StyledButton
          $transparent={transparent}
          $disabled={disabled || isFetching}
          ref={ref}
          disabled={disabled || isFetching}
          {...props}
        >
          <ContentContainer $isFetching={isFetching} className='button-content'>
            {children}
          </ContentContainer>

          {isFetching && (
            <LoaderContainer>
              <Loader color={transparent ? theme.colors.main1 : theme.colors.white} />
            </LoaderContainer>
          )}
        </StyledButton>
      );
    }
  )
);

export { Button };
