import styled from 'styled-components';

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  max-width: 583px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-right: 32px;

  a {
    color: inherit;
  }

  @media (max-width: 768px) {
    margin-right: 22px;
    font-size: 14px;
    letter-spacing: -0.28px;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 22px;
    flex-shrink: 0;
  }

  @media (max-width: ${({ theme }) => theme.screen.desktop.sm}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;
  }
`;
