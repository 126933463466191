import React, { memo, useMemo, useCallback, useState } from 'react';

import { theme } from 'modules/common/containers/ThemeProvider';

import { Text, TextVariants } from 'modules/common/components/Text';
import { Image } from 'modules/common/components/Image';

import { getInitials } from './utils';
import { Props } from './types';
import { Container, DEFAULT_AVATAR_SIZE } from './styles';

const Avatar: React.FC<Props> = memo(
  ({ size = DEFAULT_AVATAR_SIZE, image, fullName, color = theme.colors.grey, ...props }) => {
    const [isImageVisible, setIsImageVisible] = useState(() => Boolean(image));

    const styles = useMemo(() => {
      const sizeValue = `${size}px`;

      return {
        width: sizeValue,
        minWidth: sizeValue,
        height: sizeValue,
        borderRadius: sizeValue,
        backgroundColor: color,
      };
    }, [size, color]);

    const initials = useMemo(() => getInitials(fullName), [fullName]);

    const handleImageError = useCallback(() => {
      setIsImageVisible(false);
    }, []);

    return (
      <Container {...props} style={styles}>
        {isImageVisible ? (
          <Image src={image} onError={handleImageError} />
        ) : (
          <Text variant={TextVariants.Middle}>{initials}</Text>
        )}
      </Container>
    );
  }
);

export { Avatar, DEFAULT_AVATAR_SIZE };
