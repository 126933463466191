import { ReactElement } from 'react';

export interface IToken {
  accessToken: string;
  idToken: string;
}

export type Token = IToken | null;

export type CbType = (auth: AuthResult) => void;

export type AuthContextType = {
  token: Token;
  signIn: (params?: AuthModalPayload) => void;
  signUp: (params?: AuthModalPayload) => void;
  logout: () => void;
  close: () => void;
  resetToken: () => void;
  isAuthOpened: boolean;
  lockOnAuthListener: (cb: CbType) => () => void;
  isAuthorized: boolean;
};

export enum AuthTypes {
  Login = 'login',
  SignUp = 'signUp',
}

export interface AuthModalParams {
  type: AuthTypes;
  shouldLock?: boolean;
  redirectTo?: string;
  renderHeader?: (type: AuthTypes) => ReactElement;
}

export type AuthModalPayload = Omit<AuthModalParams, 'type'>;

export type ExtensibilityError = {
  code: string;
  description: string;
  name: string;
  statusCode: number;
};
