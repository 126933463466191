import styled, { css } from 'styled-components';
import { Modal } from 'modules/common/components/Modal';
import { Text, TextVariants } from 'modules/common/components/Text';
import { basicBoldStyles } from 'modules/common/components/Text/styles';
import { Row } from 'modules/common/components/Row';

import listPointSrc from 'assets/images/list-point.svg';

import { PriceBadge as PriceBadgeBase } from './components/PriceOption/styles';

export const StyledModal = styled(Modal)`
  width: 1100px;
  min-height: 665px;
  padding: 30px 30px 40px 30px;
  max-width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.screen.desktop.sm}px) {
    padding: 19px;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Title = styled(Text).attrs({ variant: TextVariants.ChatTitleHeavy })`
  color: ${({ theme }) => theme.colors.darkGrey};

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const Subtitle = styled(Text).attrs({ variant: TextVariants.BodyBold })`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: 900;

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
  }
`;

export const SubtitleSm = styled(Text).attrs({ variant: TextVariants.BasicHeavy })`
  color: ${({ theme }) => theme.colors.paynesGrey};
`;

export const SubtitleMobileSm = styled(Subtitle)`
  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    font-size: 20px;
    line-height: 1;
  }
`;

export const ContentContainer = styled.div`
  padding-top: 18px;
  display: flex;
  justify-content: space-between;
  gap: 35px;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;

    & form {
      padding: 20px 0;
    }
  }
`;

export const Container = styled.div`
  flex: 1;
`;

export const PriceOptionsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    justify-content: flex-start;
    gap: 16px;
  }
`;

export const SubscribersImage = styled.img`
  max-height: 44px;
`;

const styledTextCss = css`
  ${basicBoldStyles}

  color: ${({ theme }) => theme.colors.darkGrey};

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    font-size: 14px;
  }
`;

export const StyledText = styled.div`
  ${styledTextCss}
`;

export const SummaryContainer = styled.div`
  margin-bottom: 18px;

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    padding: 4px;
    margin-bottom: 0;
  }
`;

export const SummaryRow = styled(Row)`
  margin-top: 12px;
  justify-content: space-between;
  align-items: flex-end;

  &::before {
    content: '';
    flex: 1;
    order: 2;
    border-bottom: 1px dotted ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const SummaryRowText = styled.div`
  order: 1;
`;

export const SummaryRowPrice = styled.div`
  order: 3;
`;

const summaryTextCss = css`
  ${styledTextCss}

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    font-size: 16px;
  }
`;

export const SummaryText = styled.div`
  ${summaryTextCss}
`;

export const SummaryTextMarked = styled(SummaryText)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const SummaryTextFaded = styled(SummaryText)`
  color: ${({ theme }) => theme.colors.dayLightGrey};
`;

export const SummaryTotalDescription = styled(SubtitleMobileSm)`
  color: ${({ theme }) => theme.colors.dayGrey};
  font-weight: 500;
`;

export const RefundText = styled(Text).attrs({ variant: TextVariants.Basic })`
  color: ${({ theme }) => theme.colors.dayGrey};
  text-align: center;
  margin: 0;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const HeaderRow = styled(Row)`
  justify-content: space-between;
`;

export const PriceBadge = styled(PriceBadgeBase)`
  font-size: 12px;
  line-height: 14px;
  padding: 3px 8px;
  counter-reset: ${({ theme }) => theme.colors.textContrast};
`;

export const ListContainer = styled(Row)`
  padding: ${({ theme }) => theme.padding.base(1)};
  background-color: ${({ theme }) => theme.colors.siteAccent1};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.base(1)};
  border-radius: 6px;
`;

export const List = styled(Row)`
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.base(1)};
`;

export const ListItem = styled(Row)`
  gap: ${({ theme }) => theme.padding.base(1)};
  color: ${({ theme }) => theme.colors.paynesGrey};

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background: url('${listPointSrc}') no-repeat center;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.padding.base(2)};
`;

export const RefundRow = styled(Row)`
  margin-top: ${({ theme }) => theme.padding.base(2)};
`;
