import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { userService } from 'core/user';

import { useUser } from 'modules/user/components/UserProvider/hooks';
import { useAuth } from 'modules/user/components/AuthProvider/hooks';
import { useAnalytics } from 'modules/common/hooks/useAnalytics';

import { SubscriptionsModal } from '../SubscriptionsModal';
import { PaymentStatusModal } from '../PaymentStatusModal';
import { PaymentResultStatuses } from '../../constants';
import { SubscriptionAlreadyExists } from '../SubscriptionAlreadyExists';

import { PaymentModalsContextType } from './types';

export const PaymentModalsContext = createContext<PaymentModalsContextType>(
  {} as PaymentModalsContextType
);

const PaymentModalsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, hasProPlan, setUser, setUserConfig } = useUser();
  const { signUp, lockOnAuthListener } = useAuth();
  const { setGoogleTag } = useAnalytics();

  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<PaymentResultStatuses | null>(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [shouldLockOnAuth, setShouldLockOnAuth] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState<string | null>(null);

  const closeSubscriptionsModal = useCallback(() => {
    setSelectedPriceId(null);
    setIsSubscriptionModalOpen(false);
    setIsTrial(false);
  }, []);

  const checkBeforeOpenModal = useCallback((): boolean => {
    if (hasProPlan) {
      return false;
    }

    if (!user) {
      setShouldLockOnAuth(true);
      signUp();
      return false;
    }

    return true;
  }, [hasProPlan, user, signUp]);

  const openSubscriptionsModal = useCallback(
    (shouldUseTrial = false, subId: string | null = null) => {
      if (subId) {
        setSelectedPriceId(subId);
      }

      const canOpenModal = checkBeforeOpenModal();

      setIsTrial(shouldUseTrial);

      if (canOpenModal) {
        setIsSubscriptionModalOpen(true);
      }
    },
    [checkBeforeOpenModal]
  );

  const onCloseStatusModal = useCallback(() => {
    setPaymentStatus(null);
  }, []);

  const onRetryPayment = useCallback(() => {
    setPaymentStatus(null);

    openSubscriptionsModal(isTrial, selectedPriceId);
  }, [openSubscriptionsModal, isTrial, selectedPriceId]);

  const onCloseModal = useCallback(async () => {
    closeSubscriptionsModal();

    const userProfile = await userService.fetchUser();

    setUser(userProfile);
  }, [closeSubscriptionsModal, setUser]);

  const onShowExistingSubscription = async () => {
    setHasActiveSubscription(true);
    await onCloseModal();
  };

  const onSuccess = useCallback(
    async (paymentMethodType: string | null, amount: number, currency: string) => {
      setPaymentStatus(PaymentResultStatuses.SUCCESS);

      setGoogleTag({ event: 'payment', paymentMethodType, amount, currency });

      setUserConfig({
        isTrialAvailable: false,
      });

      await onCloseModal();
    },
    [onCloseModal, setGoogleTag, setUserConfig]
  );

  const trackPaymentError = useCallback(
    (paymentMethodType: string | null, amount: number, currency: string) => {
      setGoogleTag({ event: 'payment_error', paymentMethodType, amount, currency });
    },
    [setGoogleTag]
  );

  const value = useMemo(
    () => ({
      closeSubscriptionsModal,
      openSubscriptionsModal,
      isSubscriptionModalOpen: isSubscriptionModalOpen && !paymentStatus,
    }),
    [closeSubscriptionsModal, openSubscriptionsModal, isSubscriptionModalOpen, paymentStatus]
  );

  useEffect(() => {
    if (!shouldLockOnAuth) {
      return undefined;
    }

    return lockOnAuthListener(async () => {
      setIsWaiting(true);
    });
  }, [lockOnAuthListener, shouldLockOnAuth]);

  useEffect(() => {
    if (!isWaiting || !user) {
      return;
    }

    if (!hasProPlan) {
      setIsSubscriptionModalOpen(true);
    }

    setIsWaiting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaiting, user]);

  return (
    <PaymentModalsContext.Provider value={value}>
      {children}

      {isSubscriptionModalOpen && !paymentStatus && (
        <SubscriptionsModal
          onClose={closeSubscriptionsModal}
          onSuccess={onSuccess}
          onError={trackPaymentError}
          onSkip={onShowExistingSubscription}
          isTrial={isTrial}
          priceId={selectedPriceId}
        />
      )}

      {paymentStatus && (
        <PaymentStatusModal
          status={paymentStatus}
          onClose={onCloseStatusModal}
          onRetry={onRetryPayment}
        />
      )}

      {hasActiveSubscription && (
        <SubscriptionAlreadyExists onClose={() => setHasActiveSubscription(false)} />
      )}
    </PaymentModalsContext.Provider>
  );
};

export { PaymentModalsProvider };
export { usePaymentModals } from './hooks/usePaymentModals';
