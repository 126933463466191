import React, { memo } from 'react';
import { ThemeProvider as BaseThemeProvider } from 'styled-components';

import { theme } from './theme';
import { GlobalStyles } from './styles';

const ThemeProvider: React.FC<React.PropsWithChildren> = memo(({ children }) => {
  return (
    <BaseThemeProvider theme={theme}>
      <GlobalStyles />

      {children}
    </BaseThemeProvider>
  );
});

type Theme = typeof theme;

export { ThemeProvider, theme };
export type { Theme };
