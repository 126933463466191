import styled from 'styled-components';

export const DEFAULT_AVATAR_SIZE = 32;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
`;
