import { httpClient } from 'services/http-client';

import { BASE_URL } from './constants';
import {
  PricesResponse,
  CreatePaymentIntentPayload,
  CouponCodePayload,
  Coupon,
  CreatePaymentPayload,
  CreatePaymentResponse,
  CreatePaymentIntentResponse,
} from './types';

const subscriptionsService = {
  fetchPrices: async (): Promise<PricesResponse> => {
    const { data } = await httpClient.get<PricesResponse>(`${BASE_URL}/public/prices`);

    return data;
  },

  createPaymentIntent: async (
    payload: CreatePaymentIntentPayload
  ): Promise<CreatePaymentIntentResponse> => {
    const { data } = await httpClient.post<CreatePaymentIntentResponse>(
      `${BASE_URL}/setup-intent`,
      payload
    );

    return data;
  },

  createPayment: async (payload: CreatePaymentPayload): Promise<CreatePaymentResponse> => {
    const { data } = await httpClient.post<CreatePaymentResponse>(
      `${BASE_URL}/subscription`,
      payload
    );

    return data;
  },

  cancelSubscription: async (): Promise<void> => {
    const { data } = await httpClient.delete(`${BASE_URL}/subscription`);

    return data;
  },

  applyCoupon: async (payload: CouponCodePayload): Promise<Coupon> => {
    const { data } = await httpClient.put<Coupon>(`${BASE_URL}/coupons`, payload);

    return data;
  },
};

export { subscriptionsService };
