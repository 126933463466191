import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { theme } from 'modules/common/containers/ThemeProvider';

import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';

import { Props } from './types';
import { Content, Overlay, CloseButtonContainer, CloseButton } from './styles';

const Modal: React.FC<Props> = ({ withCloseButton = true, onClose, children, ...props }) => {
  useEffect(() => {
    document.body.classList.add('size-locked');

    return () => {
      document.body.classList.remove('size-locked');
    };
  }, []);

  const handleContentClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return ReactDOM.createPortal(
    <Overlay onClick={onClose}>
      <Content onClick={handleContentClick} {...props}>
        {withCloseButton && (
          <CloseButtonContainer>
            <CloseButton onClick={onClose}>
              <CloseIcon color={theme.colors.white} />
            </CloseButton>
          </CloseButtonContainer>
        )}

        {children}
      </Content>
    </Overlay>,
    document.body
  );
};

export { Modal };
