import React from 'react';

import { PriceOption } from '../PriceOption';
import { getDiscountData } from '../../utils';

import { PriceSelectorProps } from './types';
import { Container } from './styles';

const PriceSelector: React.FC<PriceSelectorProps> = ({
  data,
  basePrice,
  selectedSubscription,
  onClick,
}) => {
  return (
    <Container>
      {data.map((price) => {
        const { id } = price;
        const { monthlyPrice, discountPercentage, priceWithoutDiscount } = getDiscountData(
          price,
          basePrice
        );

        return (
          <PriceOption
            key={id}
            price={price}
            isSelected={selectedSubscription?.id === id}
            onClick={onClick}
            discountPercentage={discountPercentage || 0}
            monthlyPrice={monthlyPrice}
            priceWithoutDiscount={priceWithoutDiscount}
          />
        );
      })}
    </Container>
  );
};

export { PriceSelector };
