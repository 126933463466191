import React, { memo } from 'react';

import { Props } from './types';
import { StyledImage } from './styles';

const Image: React.FC<Props> = memo(({ src, ...props }) => {
  return <StyledImage {...props} src={src} />;
});

export { Image };
