import styled from 'styled-components';

import { Button } from 'modules/common/components/Button';

export const StyledButton = styled(Button)`
  box-shadow: 0 3px 12px 0 rgba(74, 58, 255, 0.18);
  letter-spacing: -0.4px;

  &:focus {
    outline: none;
  }

  svg {
    margin-right: 4px;
  }

  @media (max-width: ${({ theme }) => theme.screen.desktop.sm}px) {
    max-width: 100%;
    width: 100%;
  }
`;
