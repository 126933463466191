import React, { useCallback, memo } from 'react';

import { theme } from 'modules/common/containers/ThemeProvider';
import { getPriceTitle } from 'modules/price/utils';
import { getCurrencyLabel } from 'modules/price/components/Subscriptions/utils/getCurrencyLabel';

import { CrossedOutText } from 'modules/common/components/CrossedOutText';
import { Row } from 'modules/common/components/Row';
import { TextAsVariants, Text, TextVariants } from 'modules/common/components/Text';

import {
  PriceBadge,
  PriceContainer,
  PriceOptionContainer,
  PriceOptionRadioButton,
  PriceSubtitle,
  PriceTitle,
  PriceTitleExtended,
  NormalText,
} from './styles';
import { BadgeColor, Props } from './types';
import { getDiscountColorByPeriod } from './utils';

const PriceOption: React.FC<Props> = memo(
  ({ price, isSelected, onClick, discountPercentage, monthlyPrice, priceWithoutDiscount }) => {
    const { unitAmount, period, periodCount, currency } = price;
    const currencyLabel = getCurrencyLabel(currency);

    const handleClick = useCallback(() => {
      onClick(price);
    }, [price, onClick]);

    return (
      <PriceOptionContainer onClick={handleClick}>
        <Row>
          <PriceOptionRadioButton $selected={isSelected} />
        </Row>

        <PriceContainer>
          <Row $gap={($theme) => $theme.padding.base(0.75)}>
            <PriceTitle>
              {getPriceTitle(period, periodCount)}
              {` - `}
              {priceWithoutDiscount && (
                <NormalText as={TextAsVariants.Span}>
                  <CrossedOutText color={theme.colors.dayGreyAverage}>
                    {currencyLabel}
                    {(priceWithoutDiscount / 100).toString()}
                  </CrossedOutText>
                </NormalText>
              )}{' '}
              {currencyLabel}
              {(unitAmount / 100).toString()}
            </PriceTitle>

            <PriceTitleExtended as={TextAsVariants.Span}>/</PriceTitleExtended>

            <PriceTitleExtended as={TextAsVariants.Span}>
              {periodCount > 1 ? `${periodCount} ${period}s` : period}
            </PriceTitleExtended>
          </Row>

          <Row $gap={($theme) => $theme.padding.base(1.5)}>
            {!discountPercentage ? (
              <PriceBadge $color={BadgeColor.Transparent}>
                <Text variant={TextVariants.MiddleBold}>No discount</Text>
              </PriceBadge>
            ) : (
              <>
                <PriceBadge $color={getDiscountColorByPeriod(period, periodCount)}>
                  <Text variant={TextVariants.MiddleBold}>{discountPercentage}% off</Text>
                </PriceBadge>

                {monthlyPrice && (
                  <PriceSubtitle>
                    {currencyLabel}
                    {monthlyPrice / 100} / month
                  </PriceSubtitle>
                )}
              </>
            )}
          </Row>
        </PriceContainer>
      </PriceOptionContainer>
    );
  }
);

export { PriceOption };
