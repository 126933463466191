import React, { memo } from 'react';

import { Button } from 'modules/common/components/Button';

import { Props } from './types';
import { StyledCaption, StyledModal, StyledTypography } from './styles';

const SubscriptionAlreadyExists: React.FC<Props> = memo(({ onClose }) => {
  return (
    <StyledModal>
      <StyledTypography>Oops!</StyledTypography>

      <div>
        <StyledCaption>Seems like you already have an existing active subscription.</StyledCaption>
        <StyledCaption>
          Please try again or <a href=''>contact us</a> if problem persists.
        </StyledCaption>
      </div>

      <Button onClick={onClose}>Got it</Button>
    </StyledModal>
  );
});

export { SubscriptionAlreadyExists };
