import styled from 'styled-components';

import { Modal } from 'modules/common/components/Modal';

export const StyledModal = styled(Modal)`
  text-align: center;
  max-width: 425px;

  & .auth0-lock-widget {
    width: 360px !important;
    padding: 0 !important;

    @media (max-width: ${({ theme }) => theme.screen.mobile.xlg}px) {
      width: 300px !important;
    }

    @media (max-width: ${({ theme }) => theme.screen.mobile.md}px) {
      width: 100% !important;
      max-width: 300px !important;
    }
  }

  & .auth0-lock-form {
    padding: ${({ theme }) => theme.padding.base(4)} 0 !important;
  }

  & .auth0-lock-submit {
    border: 0;
    cursor: pointer;
    border-radius: 50px !important;
    color: ${({ theme }) => theme.colors.white} !important;
    background-color: ${({ theme }) => theme.colors.main1} !important;
    padding: ${({ theme }) => `${theme.padding.base(1.25)} ${theme.padding.base(2)}`} !important;
    font-family: ${({ theme }) => theme.fonts.primary} !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
  }
`;

export const ChangeTypeContainer = styled.div`
  margin-top: ${({ theme }) => theme.padding.base(4)};
`;

export const ChangeTypeText = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.main1};
  cursor: pointer;
`;
