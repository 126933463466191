import styled from 'styled-components';

export const StyledText = styled.span<{ $color: string }>`
  display: inline-block;
  position: relative;
  color: ${({ $color }) => $color};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 1px;
    background-color: ${({ $color }) => $color};
  }
`;
