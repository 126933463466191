import styled from 'styled-components';

const DOT_SIZE = 8;

export const Container = styled.div<{ $color: string }>`
  align-items: center;
  display: flex;

  .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: ${({ $color }) => $color};
    border-radius: 50%;
    height: ${DOT_SIZE}px;
    width: ${DOT_SIZE}px;
    margin-right: ${({ theme }) => theme.padding.base(0.5)};
    vertical-align: middle;
    display: inline-block;

    &:nth-child(1) {
      animation-delay: 200ms;
    }

    &:nth-child(2) {
      animation-delay: 300ms;
    }

    &:nth-child(3) {
      animation-delay: 400ms;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);

      background-color: ${({ theme, $color }) => theme.utils.hexToRgba($color, 0.7)};
    }
    28% {
      transform: translateY(-${DOT_SIZE}px);
      background-color: ${({ theme, $color }) => theme.utils.hexToRgba($color, 0.4)};
    }
    44% {
      transform: translateY(0px);
      background-color: ${({ theme, $color }) => theme.utils.hexToRgba($color, 0.2)};
    }
  }
`;
