import styled from 'styled-components';
import { Modal } from 'modules/common/components/Modal';
import { Text } from 'modules/common/components/Text';

export const StyledModal = styled(Modal)`
  width: 476px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  button {
    min-width: 120px;
  }
`;

export const StyledTypography = styled(Text)`
  text-align: center;
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.screen.mobile.sm}px) {
    margin: 0;
  }
`;

export const StyledCaption = styled.p`
  margin: 0;
  text-align: center;
  font-size: 18px;

  & + & {
    margin-top: 16px;
  }

  a {
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`;
