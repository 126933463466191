import { Price } from 'core/subscriptions/types';

export interface Props {
  price: Price;
  isSelected: boolean;
  onClick: (price: Price) => void;
  monthlyPrice: number | null;
  priceWithoutDiscount: number | null;
  discountPercentage: number | null;
}

export enum BadgeColor {
  Primary = 'primary',
  Transparent = 'transparent',
}
