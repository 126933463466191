import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY } from './constants';
import { ExtensibilityError } from './types';

export const handleExtensibilityError = (error: ExtensibilityError) => {
  const insertMessage = (msg: string) => {
    const errorWrapper = document.querySelector(
      '.auth0-lock-content-body-wrapper > div:first-child > div:first-child'
    );

    if (errorWrapper) {
      errorWrapper.innerHTML = `
      <div class="auth0-global-message auth0-global-message-error">
        <span class="animated fadeInUp">
          <span>${msg}</span>
        </span>
      </div>
    `;
    }
  };

  if (error.code === 'extensibility_error' && error.description === 'USER_ALREADY_EXISTS') {
    setTimeout(() => {
      insertMessage('This email is already registered. Try to login instead?');
    }, 300);
  }
};

export const cleanUrlHash = () =>
  window.history.pushState('', document.title, window.location.pathname + window.location.search);

export const getToken = () => {
  const searchParams = new URLSearchParams(window.location.hash);
  const tokenKeyParam = searchParams.get(`#${ACCESS_TOKEN_KEY}`);
  const idTokenParam = searchParams.get(ID_TOKEN_KEY);
  const accessToken = tokenKeyParam || localStorage.getItem(ACCESS_TOKEN_KEY);
  const idToken = idTokenParam || localStorage.getItem(ID_TOKEN_KEY);

  if (tokenKeyParam && idTokenParam) {
    localStorage.setItem(ACCESS_TOKEN_KEY, tokenKeyParam);
    localStorage.setItem(ID_TOKEN_KEY, idTokenParam);
  }

  if (accessToken && idToken) {
    return { accessToken, idToken };
  }

  return null;
};
