import React, { useCallback, useMemo, useState, memo, useEffect } from 'react';
import { getPriceTitle, getPriceWithoutDiscount } from 'modules/price/utils';

import { Price, Coupon } from 'core/subscriptions/types';

import SubscribersImg from 'assets/images/subscribers.png';
import { ReactComponent as StripeIcon } from 'assets/images/powered-by-stripe.svg';

import { addDaysToDate, formatDate } from 'utils/date';

import { useWindowSize } from 'modules/common/hooks/useWindowSize';
import { theme } from 'modules/common/containers/ThemeProvider';
import { useAnalytics } from 'modules/common/hooks/useAnalytics';
import { CrossedOutText } from 'modules/common/components/CrossedOutText';
import { Text, TextVariants } from 'modules/common/components/Text';
import { Row } from 'modules/common/components/Row';
import { Loader } from 'modules/common/components/Loader';

import { useSubscriptions } from 'modules/price/components/Subscriptions/hooks/useSubscriptions';
import { getCurrencyLabel } from 'modules/price/components/Subscriptions/utils/getCurrencyLabel';

import { getDiscountData, isMonthlySubscription, sortPrices } from './utils';
import { PriceSelector } from './components/PriceSelector';

import { SubscriptionsModalProps } from './types';
import {
  Container,
  ContentContainer,
  HeaderRow,
  List,
  ListContainer,
  ListItem,
  PriceBadge,
  PriceOptionsContainer,
  RefundText,
  SpinnerWrapper,
  StyledModal,
  StyledText,
  SubscribersImage,
  Subtitle,
  SubtitleMobileSm,
  SubtitleSm,
  SummaryContainer,
  SummaryRow,
  SummaryRowPrice,
  SummaryRowText,
  SummaryText,
  SummaryTextFaded,
  SummaryTextMarked,
  SummaryTotalDescription,
  Title,
  FooterContainer,
  RefundRow,
} from './styles';
import { getDiscountColorByPeriod } from './components/PriceOption/utils';

const PaymentElements = React.lazy(() => import('../PaymentElements'));

const SubscriptionsModal: React.FC<SubscriptionsModalProps> = memo(
  ({ priceId = null, onClose, onError, onSkip, onSuccess, isTrial }) => {
    const { isMobile } = useWindowSize();
    const { isFetching, subscriptions, country } = useSubscriptions();

    const { setGoogleTag } = useAnalytics();

    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string | null>(priceId);

    const sortedPrices = useMemo(() => sortPrices(subscriptions) || [], [subscriptions]);

    const subscription = useMemo<Price | null>(() => {
      if (selectedSubscriptionId) {
        return subscriptions.find(({ id }) => id === selectedSubscriptionId) || null;
      }

      return subscriptions.find(({ period }) => period === 'year') || null;
    }, [subscriptions, selectedSubscriptionId]);

    const [coupon, setCoupon] = useState<Coupon | null>(null);

    const paymentUnitAmount = useMemo(() => {
      if (!subscription) {
        return 0;
      }

      return coupon?.percentOff
        ? Math.floor(subscription.unitAmount - subscription.unitAmount * (coupon.percentOff / 100))
        : subscription.unitAmount;
    }, [coupon, subscription]);

    const basePrice = useMemo(() => {
      return subscriptions.find(isMonthlySubscription);
    }, [subscriptions]);

    const priceWithoutDiscount = useMemo(() => {
      if (!subscription || !basePrice) {
        return null;
      }

      if (isMonthlySubscription(subscription)) {
        return null;
      }

      return getPriceWithoutDiscount(
        subscription.period,
        subscription.periodCount,
        basePrice.unitAmount
      );
    }, [basePrice, subscription]);

    const handleSubscriptionChange = useCallback(
      (value: Price) => {
        setGoogleTag({ event: 'plan_click' });

        setSelectedSubscriptionId(value.id);
      },
      [setGoogleTag]
    );

    const discountData = useMemo(() => {
      if (!subscription) {
        return null;
      }

      return getDiscountData(subscription, basePrice || null);
    }, [subscription, basePrice]);

    useEffect(() => {
      setGoogleTag({ event: 'show_payment_form' });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const title = isTrial ? 'Start your free trial to get more' : 'Pay & Subscribe';

    return (
      <StyledModal onClose={onClose}>
        <HeaderRow>
          <Title>{title}</Title>
        </HeaderRow>

        <ContentContainer>
          <PriceOptionsContainer>
            {isFetching ? (
              <SpinnerWrapper>
                <Loader />
              </SpinnerWrapper>
            ) : (
              <>
                <Container>
                  <PriceSelector
                    data={sortedPrices}
                    basePrice={basePrice || null}
                    selectedSubscription={subscription}
                    onClick={handleSubscriptionChange}
                  />

                  <ListContainer>
                    <SubtitleSm>Unlimited access to features</SubtitleSm>

                    <List>
                      <ListItem>
                        <Text variant={TextVariants.Basic}>Automated task understanding</Text>
                      </ListItem>
                      <ListItem>
                        <Text variant={TextVariants.Basic}>Reliable solutions</Text>
                      </ListItem>
                      <ListItem>
                        <Text variant={TextVariants.Basic}>Step-by-step tutoring</Text>
                      </ListItem>
                      <ListItem>
                        <Text variant={TextVariants.Basic}>Personalized explanations</Text>
                      </ListItem>
                    </List>
                  </ListContainer>
                </Container>

                <FooterContainer>
                  <Row $gap={($theme) => $theme.padding.base(1.5)}>
                    <SubscribersImage src={SubscribersImg} alt='Subscribers' />

                    <StyledText>Over 21,176 users have subscribed to our pro account.</StyledText>
                  </Row>

                  {!isMobile && (
                    <Row>
                      <StripeIcon />
                    </Row>
                  )}
                </FooterContainer>
              </>
            )}
          </PriceOptionsContainer>

          <Container>
            {subscription && (
              <SummaryContainer>
                <Subtitle>Summary</Subtitle>

                <SummaryRow>
                  <SummaryRowText>
                    <SummaryText>
                      Pro subscription (
                      {getPriceTitle(subscription.period, subscription.periodCount)})
                    </SummaryText>
                  </SummaryRowText>

                  <SummaryRowPrice>
                    {!isTrial && (
                      <SummaryText>
                        {discountData?.discountPercentage ? (
                          <CrossedOutText color={theme.colors.paynesGrey}>
                            {getCurrencyLabel(subscription.currency)}
                            {(priceWithoutDiscount || 0) / 100}
                          </CrossedOutText>
                        ) : (
                          <span>
                            {getCurrencyLabel(subscription.currency)}
                            {subscription.unitAmount / 100}
                          </span>
                        )}
                      </SummaryText>
                    )}

                    {isTrial && (
                      <SummaryText>
                        <CrossedOutText color={theme.colors.paynesGrey}>
                          {getCurrencyLabel(subscription.currency)}
                          {paymentUnitAmount / 100}
                        </CrossedOutText>
                      </SummaryText>
                    )}
                  </SummaryRowPrice>
                </SummaryRow>

                {!isTrial && (
                  <SummaryRow>
                    <SummaryRowText>
                      <Row $gap={($theme) => $theme.padding.base(1)}>
                        {discountData?.discountPercentage ? (
                          <>
                            <SummaryTextMarked>Discount</SummaryTextMarked>

                            <PriceBadge
                              $color={getDiscountColorByPeriod(
                                subscription.period,
                                subscription.periodCount
                              )}
                            >
                              {discountData?.discountPercentage}% off
                            </PriceBadge>
                          </>
                        ) : (
                          <SummaryTextFaded>Discount</SummaryTextFaded>
                        )}
                      </Row>
                    </SummaryRowText>

                    <SummaryRowPrice>
                      {discountData?.discountPercentage ? (
                        <SummaryTextMarked>
                          -{getCurrencyLabel(subscription.currency)}
                          {((priceWithoutDiscount || 0) - paymentUnitAmount) / 100}
                        </SummaryTextMarked>
                      ) : (
                        <SummaryTextFaded>No discount</SummaryTextFaded>
                      )}
                    </SummaryRowPrice>
                  </SummaryRow>
                )}

                <SummaryRow>
                  <SummaryRowText>
                    <Subtitle>Total</Subtitle>
                  </SummaryRowText>

                  <SummaryRowPrice>
                    <Row $gap={($theme) => $theme.padding.base(0.5)}>
                      {isTrial ? (
                        <SubtitleMobileSm>
                          {getCurrencyLabel(subscription.currency)}
                          {0}
                        </SubtitleMobileSm>
                      ) : (
                        <>
                          <SubtitleMobileSm>
                            {getCurrencyLabel(subscription.currency)}
                            {paymentUnitAmount / 100}
                          </SubtitleMobileSm>
                          <SummaryTotalDescription>/</SummaryTotalDescription>
                          <SummaryTotalDescription>
                            {getPriceTitle(subscription.period, subscription.periodCount)}
                          </SummaryTotalDescription>
                        </>
                      )}
                    </Row>
                  </SummaryRowPrice>
                </SummaryRow>
              </SummaryContainer>
            )}

            <Row>
              <React.Suspense
                fallback={
                  <SpinnerWrapper>
                    <Loader />
                  </SpinnerWrapper>
                }
              >
                {subscription && (
                  <PaymentElements
                    country={country}
                    activeSubscription={subscription}
                    unitAmount={paymentUnitAmount}
                    coupon={coupon}
                    onSetCoupon={setCoupon}
                    onClose={onClose}
                    onSkip={onSkip}
                    onError={onError}
                    onSuccess={onSuccess}
                    hideCouponInput
                    priceWithoutDiscount={priceWithoutDiscount}
                    isTrial={isTrial}
                  />
                )}
              </React.Suspense>
            </Row>

            <RefundRow>
              {isTrial ? (
                subscription && (
                  <RefundText>
                    {getCurrencyLabel(subscription.currency)}
                    {paymentUnitAmount / 100}/
                    {getPriceTitle(subscription.period, subscription.periodCount, true)}
                    {` starting ${formatDate(addDaysToDate(2).getTime() / 1000)}`}
                  </RefundText>
                )
              ) : (
                <RefundText>
                  Unlimited use of AI Tutor,
                  <br />
                  no-questions-asked 7 days refund guarantee
                </RefundText>
              )}
            </RefundRow>

            {isMobile && (
              <Row>
                <StripeIcon width={128} />
              </Row>
            )}
          </Container>
        </ContentContainer>
      </StyledModal>
    );
  }
);

export { SubscriptionsModal };
