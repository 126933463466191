import { httpClient } from 'services/http-client';

import {
  BASE_URL,
  USER_ALLOWED_ACTIONS_CONFIG_STORAGE_KEY,
  USER_ALLOWED_ACTIONS_CONFIG_DEFAULT,
  USER_CONFIG_STORAGE_KEY,
  USER_CONFIG_DEFAULT,
} from './constants';
import { UserProfile, UserAllowedActionsConfig, UserConfig } from './types';

const userService = {
  fetchUser: async (): Promise<UserProfile> => {
    const { data } = await httpClient.get<UserProfile>(`${BASE_URL}/me`);

    return data;
  },

  getUserAllowedActionsConfig: (): UserAllowedActionsConfig => {
    const json = localStorage.getItem(USER_ALLOWED_ACTIONS_CONFIG_STORAGE_KEY);

    if (!json) {
      userService.setUserAllowedActionsConfig(USER_ALLOWED_ACTIONS_CONFIG_DEFAULT);

      return userService.getUserAllowedActionsConfig();
    }

    return JSON.parse(json) as UserAllowedActionsConfig;
  },

  setUserAllowedActionsConfig: (payload: UserAllowedActionsConfig): void => {
    localStorage.setItem(USER_ALLOWED_ACTIONS_CONFIG_STORAGE_KEY, JSON.stringify(payload));
  },

  getUserConfig: (): UserConfig => {
    const json = localStorage.getItem(USER_CONFIG_STORAGE_KEY);

    if (!json) {
      const defaultConfig = {
        ...USER_CONFIG_DEFAULT,
      };

      userService.setUserConfig(defaultConfig);

      return userService.getUserConfig();
    }

    return JSON.parse(json) as UserConfig;
  },

  setUserConfig: (payload: UserConfig): void => {
    localStorage.setItem(USER_CONFIG_STORAGE_KEY, JSON.stringify(payload));
  },
};

export { userService };
