import React from 'react';

import { ReactComponent as CookieIcon } from 'assets/images/cookie-button-icon.svg';

import { StyledButton } from './styles';

const AgreeButton: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <CookieIcon />
      Save & close
    </StyledButton>
  );
};

export { AgreeButton };
