import styled from 'styled-components';

import { Theme } from 'modules/common/containers/ThemeProvider';

export const Row = styled.div<{
  $gap?: number | ((theme: Theme) => number);
  $justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  $alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  $disabled?: boolean;
  $isReversed?: boolean;
}>`
  display: ${({ $disabled }) => ($disabled ? 'block' : 'flex')};
  flex-direction: ${({ $isReversed }) => ($isReversed ? 'row-reverse' : 'row')};
  align-items: ${({ $alignItems = 'center' }) => $alignItems};
  justify-content: ${({ $justifyContent = 'flex-start' }) => $justifyContent};
  gap: ${({ $gap, theme }) => {
    if ($gap === undefined) {
      return '0px';
    }

    if (typeof $gap === 'function') {
      return $gap(theme);
    }

    return `${$gap}`;
  }};
`;
