import React, { memo } from 'react';

import { theme } from 'modules/common/containers/ThemeProvider';

import { Props } from './types';
import { Container } from './styles';

const Loader: React.FC<Props> = memo(({ color = theme.colors.main1 }) => {
  return (
    <Container $color={color}>
      <div className='dot' />
      <div className='dot' />
      <div className='dot' />
    </Container>
  );
});

export { Loader };
