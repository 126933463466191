import Auth0Lock from 'auth0-lock';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from 'config';
import { AUTH_0_RESPONSE_TYPE, AUTH_0_SCOPE } from '../constants';

const lockService = new Auth0Lock(AUTH0_CLIENT_ID, AUTH0_DOMAIN, {
  mustAcceptTerms: true,
  avatar: null,
  auth: {
    redirect: false,
    redirectUrl: window.location.origin,
    responseType: AUTH_0_RESPONSE_TYPE,
    audience: AUTH0_AUDIENCE,
    params: {
      scope: AUTH_0_SCOPE,
    },
  },
  container: 'auth0-lock-container',
  theme: {
    authButtons: {},
  },
  rememberLastLogin: false,
  languageDictionary: {
    loginLabel: 'Login',
    loginSubmitLabel: 'Login',
    signUpTerms:
      "By signing up, you agree to our <a href='/terms-of-service' target='_blank' rel='noreferrer'>terms of service</a> and <a href='/privacy-policy' target='_blank' rel='noreferrer'>privacy policy</a>.",
    error: {
      signUp: {
        user_exists: 'This email is already registered. Try to login instead?',
        username_exists: 'This email is already registered. Try to login instead?',
      },
    },
  },
  allowShowPassword: true,
});

export { lockService };
