import React from 'react';
import { pdfjs } from 'react-pdf';

import 'react-image-crop/dist/ReactCrop.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'tldraw/tldraw.css';

import { ThemeProvider } from './modules/common/containers/ThemeProvider';
import { MathJaxProvider } from './modules/common/containers/MathJaxProvider';
import { RouteWrapper } from './modules/router/components/RouteWrapper';
import { PageLayout } from './modules/common/components/PageLayout';
import { AuthProvider } from './modules/user/components/AuthProvider';
import { UserProvider } from './modules/user/components/UserProvider';
import { PaymentModalsProvider } from './modules/price/components/Subscriptions/components/PaymentModalsProvider';
import { ABTestsProvider } from './modules/common/containers/ABTestsProvider';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <MathJaxProvider>
        <ABTestsProvider>
          <AuthProvider>
            <UserProvider>
              <PaymentModalsProvider>
                <PageLayout>
                  <RouteWrapper />
                </PageLayout>
              </PaymentModalsProvider>
            </UserProvider>
          </AuthProvider>
        </ABTestsProvider>
      </MathJaxProvider>
    </ThemeProvider>
  );
};

export { App };
