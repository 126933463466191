import { AuthTypes } from 'modules/user/components/AuthProvider/types';

export const getTitle = (type: AuthTypes) => {
  return type === AuthTypes.Login ? 'Login' : 'Sign Up';
};

export const getText = (type: AuthTypes) => {
  return type === AuthTypes.SignUp ? 'Already have an account?' : 'New here?';
};

export const getAnotherAuth = (type: AuthTypes) => {
  return type === AuthTypes.Login ? 'Create an account' : 'Login';
};
