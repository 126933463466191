import React, { createContext, PropsWithChildren, useMemo } from 'react';

import { ABTestsContext } from './types';
// import { useSolutionsLimitCohort } from './cohorts/useSolutionsLimitCohort';

export const ABTestsProviderContext = createContext<ABTestsContext>(
  null as unknown as ABTestsContext
);

const ABTestsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // const solutionsLimit = useSolutionsLimitCohort();

  const tests = useMemo(() => ({}), []);

  return (
    <ABTestsProviderContext.Provider value={tests}>{children}</ABTestsProviderContext.Provider>
  );
};

export { ABTestsProvider };
export { useABTests } from './hooks/useABTests';
